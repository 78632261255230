@charset "utf-8";
/***
    The new CSS reset - version 1.8.5 (last updated 14.6.2023)
    GitHub page: https://github.com/elad2412/the-new-css-reset
***/

/*
    Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property
    - The "symbol *" part is to solve Firefox SVG sprite bug
    - The "html" attribute is exclud, because otherwise a bug in Chrome breaks the CSS hyphens property (https://github.com/elad2412/the-new-css-reset/issues/36)
 */
*:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
    all: unset;
    display: revert;
}

/* Preferred box-sizing value */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Reapply the pointer cursor for anchor tags */
a, button {
    cursor: revert;
}

/* Remove list styles (bullets/numbers) */
ol, ul, menu {
    list-style: none;
}

/* For images to not be able to exceed their container */
img {
    max-inline-size: 100%;
    max-block-size: 100%;
}

/* removes spacing between cells in tables */
table {
    border-collapse: collapse;
}

/* Safari - solving issue when using user-select:none on the <body> text input doesn't working */
input, textarea {
    -webkit-user-select: auto;
}

/* revert the 'white-space' property for textarea elements on Safari */
textarea {
    white-space: revert;
}

/* minimum style to allow to style meter element */
meter {
    -webkit-appearance: revert;
    appearance: revert;
}

/* preformatted text - use only for this feature */
:where(pre) {
    all: revert;
}

/* reset default text opacity of input placeholder */
::placeholder {
    color: unset;
}

/* remove default dot (•) sign */
::marker {
    content: initial;
}

/* fix the feature of 'hidden' attribute.
   display:revert; revert to element instead of attribute */
:where([hidden]) {
    display: none;
}

/* revert for bug in Chromium browsers
   - fix for the content editable attribute will work properly.
   - webkit-user-select: auto; added for Safari in case of using user-select:none on wrapper element */
:where([contenteditable]:not([contenteditable="false"])) {
    -moz-user-modify: read-write;
    -webkit-user-modify: read-write;
    overflow-wrap: break-word;
    -webkit-line-break: after-white-space;
    -webkit-user-select: auto;
}

/* apply back the draggable feature - exist only in Chromium and Safari */
:where([draggable="true"]) {
    -webkit-user-drag: element;
}

/* Revert Modal native behavior */
:where(dialog:modal) {
    all: revert;
}



/* Actual CSS */

/* montserrat-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/montserrat-v26-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/montserrat-v26-latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/montserrat-v26-latin-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/montserrat-v26-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url('./assets/fonts/montserrat-v26-latin-800.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-900 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src: url('./assets/fonts/montserrat-v26-latin-900.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

:root {
  --safe-area-inset-bottom: env(safe-area-inset-bottom);

  --color-intensity-70: #2d9108;
  --color-intensity-80: #f4c512;
  --color-intensity-90: #d99100;
  --color-intensity-100: #ed290f;

  --ion-safe-area-top: env(safe-area-inset-top);
  --ion-safe-area-bottom: env(safe-area-inset-bottom);
  --ion-safe-area-left: env(safe-area-inset-left);
  --ion-safe-area-right: env(safe-area-inset-right);
}

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  color: #000;
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}


strong {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 0.875em;
}

p a {
  text-decoration: underline;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000;
  min-height: 52px;
  color: white;
  padding: 16px 24px; /* To ensure the icons do not stick on the extreme edge of the page */
  position: sticky;
  top: env(safe-area-inset-top);
  z-index: 1000;
}

.navigation {
  position: absolute;
  top: 52px;
  background: white;
  color: black;
  right: 0;
  z-index: 10;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  pointer-events: none;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.2s ease-in-out;
  font-size: 14px;
}

.navigation.is-open {
  pointer-events: all;
  opacity: 1;
  transform: translateY(0);
}

.navigation ul {
  padding: 0.75em 1.5em 0.75em 1.5em;
}

.navigation li {
  padding: 0.75em 0;
  font-weight: 500;
}

.navigation li.is-active {
  font-weight: 700;
  color: #fcb424;
}

.header__language-switcher {
  display: flex;
  padding: 0.5em 1.5em 0.5em 1.5em;
  gap: 1em;
  text-transform: uppercase;
  font-size: 0.875em;
  font-weight: 600;
  border-top: 1px solid #ccc;
}

.header__language-switcher * {
  padding: 1em 0.5em;
}

.header__language-switcher .active {
  font-weight: 700;
  color: #fcb424;
}

.flex-center {
  display: flex;
  align-items: center;
}

.header__logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 130px;
  height: 20px;
}

.page {
  max-width: 800px;
  margin: 0 auto;
}

.page--login {
  display: flex;
  flex-direction: column;
  height: 100dvh;
}

.page--login form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 100%
}

.page--login form.is-loading {
  opacity: 0.5;
  pointer-events: none;
}

input {
  border: 1px solid black;
  border-radius: 6px;
  display: block;
  padding: 1em 1em;
  width: 100%;
}

.login-header {
  background-image: url('https://eisenhorn.com/wp-content/uploads/231229-uebungen-mike5.jpg');
  background-size: cover;
  height: 35dvh;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
  align-items: center;
}

.login-header__logo {
  z-index: 5;
  width: 250px;
  align-self: center;
}

.login-header::after {
  background: black;
  content: "";
  position: absolute;
  opacity: 0.7;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  z-index: 1;
}

.login-content {
  height: 65dvh;
  padding: 32px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.login-content h1 {
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 0.5em;
  display: none;
}

.login-content__intro {
  font-weight: 500;
  line-height: 145%;
  font-size: 0.9em;
  margin-bottom: 2em;
}

.workout-detail {
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 52px);
  max-width: 1000px;
}

.login-content__invite {
  margin-top: auto;
  line-height: 145%;
  font-weight: 500;
}

.login-content a {
  font-weight: 800;
  color: #f8b847;
}

@media (min-width: 1000px) {
  .workout-detail {
    flex-direction: row;
    justify-content: center;
    margin: 0 auto;
    align-items: center;
    gap: 50px;
  }

  .workout-detail__exercise-meta-wrapper {
    max-width: 45%;
  }

  .workout-detail__exercise-timer-wrapper {
    flex: 1;
  }
}

.workout-detail__exercise-timer-interval {
  font-size: 13px;
  opacity: 0.3;
  font-weight: 600;
  margin-bottom: 4px;
}

.workout-detail__meta {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 4px;
  padding: 0 32px;
}

@media (min-width: 1000px) {
  .workout-detail__meta {
    padding: 0;
  }
}

.workout-detail__exercise-name {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 800;
  margin-bottom: 12px;
  padding: 0 32px;
}

@media (min-width: 1000px) {
  .workout-detail__exercise-name {
    padding: 0;
  }
}

.workout-detail__exercise-image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.workout-detail__exercise-image img {
  object-fit: cover;
}



@media (max-height: 800px) {
  .workout-detail__exercise-image {
    background: black;
  }

  .workout-detail__exercise-image img {
    max-height: 47dvh;
  }
}

@media (max-height: 750px) {
  .workout-detail__exercise-image img {
    max-height: 45dvh;
    object-fit: contain;
  }
}

@media (max-height: 700px) {
  .workout-detail__exercise-image img {
    max-height: 43dvh;
  }
}

@media (max-height: 670px) {
  .workout-detail__exercise-image img {
    max-height: 40dvh;
  }
}


.workout-detail__next-set-info {
  background: white;
  border-radius: 10px;
  padding: 16px;
  font-weight: 500;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  position: absolute;
  max-width: 80%;
  line-height: 1.45em;
  font-size: 14px;
}

.workout-detail__next-set-info h2 {
  font-weight: 700;
  margin-bottom: 0.5em;
}

.workout-detail__exercise-links ul li a {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 12px;
  font-size: 14px;
}

.workout-detail__exercise-links ul li + li {
  border-top: 1px solid rgba(0,0,0,0.05);
}

.workout-detail__exercise-timer {
  margin-top: auto;
  transition: opacity 250ms ease-in-out;
  position: fixed;
  bottom: 24px;
  width: 100%;
  padding: 24px 32px 0 32px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background: #fff;
}

@media (min-width: 1000px) {
  .workout-detail__exercise-timer {
    position: relative;
  }
}

@media (display-mode: standalone), (display-mode: minimal-ui) {
  .workout-detail__exercise-timer {
    bottom: 24px;
  }
}

.workout-detail__exercise-timer.is-disabled > * {
  opacity: 0.3;
}

.workout-detail__exercise-timer.is-paused > * {
  opacity: 0.5;
}

.workout-detail__exercise-timer.is-paused .btn-group {
  opacity: 1;
}

.is-disabled .workout-detail__exercise-timer__interval-info {
  opacity: 1;
  font-size: 15px;
  padding-bottom: 80px;
}

.workout-detail__exercise-timer__interval-info h2 {
  font-weight: 700;
  font-size: 1.1em;
  margin-bottom: 1.125em;
  text-align: center;
}

.workout-detail__exercise-timer__interval-fact {
  display: flex;
  gap: 0.75em;
}

.workout-detail__exercise-timer__interval-fact-title {
  font-weight: 600;
  width: 25%;
  flex-shrink: 0;
}

.workout-detail__exercise-timer__interval-fact-content {
  font-weight: 500;
  line-height: 1.45em;
}

.workout-detail__exercise-timer__interval-fact + .workout-detail__exercise-timer__interval-fact {
  margin-top: 1em;
}

.workout-detail__exercise-timer.is-disabled .workout-detail__exercise-start-workout {
  bottom: 0;
  left: 50%;
  opacity: 1;
  position: absolute;
  transform: translateX(-50%);
  z-index: 10;
  width: calc(100% - 64px);
}

.workout-detail__exercise-timer-title {
  text-align: left;
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 4px;
}

.workout-detail__exercise-timer-time-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(0,0,0,0.03);
  border-radius: 10px;
  padding: 10px 16px 9px;
  margin-bottom: 24px;
  position: relative;
}

.workout-detail__exercise-timer-time {
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  text-align: center;
  min-width: 190px;
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: center;
}

.workout-detail__exercise-timer-time::before,
.workout-detail__exercise-timer-time::after {
  content: "";
  width: 1px;
  background-color: rgba(0,0,0,0.3);
  height: 20px;
  display: block;
}


.workout-detail__exercise-timer-meta {
  font-size: 15px;
  font-weight: 600;
  opacity: 0.5;
  display: flex;
}

.workout-detail__exercise-timer-meta--sets {
  opacity: 1;
}

.workout-detail__exercise-timer-meta--sets > span {
  opacity: 0.5;
}

.workout-detail__exercise-timer-sets {
  display: flex;
  align-items: center;
}

.workout-detail__exercise-timer-meta-set {
  display: flex;
  align-items: center;
  opacity: 0.25;
}

.workout-detail__exercise-timer-meta-set.active {
  opacity: 1;
  font-weight: 700;
  color: #E84E4E;
}

.workout-detail__exercise-timer-meta-set.active-rest {
  opacity: 1;
}

.workout-detail__exercise-timer-meta-set.active-rest span {
  opacity: 0.25;
}

.workout-detail__exercise-timer-meta-set:not(:last-child)::after {
  content: "";
  display: block;
  height: 10px;
  width: 3px;
  background-color: black;
  margin: 0 8px;
  opacity: 0.25;
}

.workout-detail__exercise-timer-meta-set.active::after {
  opacity: 0.0625;
}

.workout-detail__exercise-timer-meta-set.active-rest::after {
  opacity: 1;
  background-color: #2D8130;
  margin: 0 9px;
}

.workout-detail__exercise-timer-meta-wrapper:last-child {
  text-align: right;
}

.workout-detail__exercise-timer-intensity {
  margin-bottom: 24px;
}

.workout-detail__exercise-timer-intensity-item-wrapper {
  display: flex;
  gap: 5px;
  justify-content: space-between;
  margin-bottom: 12px;
}

.workout-detail__exercise-timer-itensity-item {
  background: rgba(0,0,0,0.15);
  height: 18px;
  flex: 1;
  border-radius: 3px;
}

.workout-detail__exercise-timer-intensity-info-wrapper {
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: rgba(0,0,0,0.3);
  gap: 1em;
  align-items: flex-start;
}

.workout-detail__exercise-timer-intensity-title {
  display: flex;
  align-items: center;
  gap: 0.325em;
}

.workout-detail__exercise-timer-intensity-title img {
  opacity: 0.5;
}

.workout-detail__exercise-timer-intensity-text {
  font-weight: 700;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  flex: 1;
}

.workout-detail__exercise-timer-progress-wrapper {
  width: 100%;
  height: 16px;
  background: rgba(0,0,0,0.1);
  margin-bottom: 24px;
}

.workout-detail__exercise-timer-progress {
  background: #FCB424;
  height: 16px;
}

.workout-detail__exercise-start-workout {

}

.loading-screen {
  background: #000;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.btn-group {
  display: flex;
  gap: 10px;
}

.btn {
  border-radius: 6px;
  background: #000;
  text-align: center;
  width: 100%;
  padding: 0.75em 0.75em;
  color: #fff;
  font-size: 15px;
  font-weight: 800;
  text-transform: uppercase;
}

.btn:hover {
  cursor: pointer;
}

.btn--ghost {
  border: 2px solid #000;
  background: transparent;
  color: #000;
}

.btn--small {
  font-size: 13px;
}

.btn--large {
  font-size: 20px;
  padding: 16px 0;
}

.btn--text {
  background: transparent;
  color: #000;
  font-size: 12px;
}

.week-navigation {
  display: flex;
  justify-content: space-between;
  padding: 1em 2em;
  font-weight: 700;
  text-transform: uppercase;
}

.week-navigation.week-navigation--workout {
  padding: 0;
  margin-bottom: 40px;
}

.week-navigation__day {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.week-navigation__day:hover {
  cursor: pointer;
}

.week-navigation.week-navigation--workout .week-navigation__day {
  gap: 8px;
}

.week-navigation .week-navigation__day-name {
  font-size: 15px;
}

.week-navigation.week-navigation--workout .week-navigation__day-name {
  font-size: 14px;
}

.week-navigation.week-navigation--workout .week-navigation__day-name {
  font-weight: 700;
}

.week-navigation__workout-indicator {
  width: 1.4em;
  height: 1.4em;
  border-radius: 50%;
  background: rgba(0,0,0,0.1);
  border: 0 solid rgba(0,0,0,0);
  text-align: center;
}

.week-navigation__workout-indicator img {
  width: 100%;
}

.week-navigation__day.is-active.is-future .week-navigation__workout-indicator,
.week-navigation__day.is-active.is-today .week-navigation__workout-indicator {
  border-color: #FCB424;
  border-width: 2px;
}

.week-navigation__day.is-today .week-navigation__day-name {
  color: #FCB424;
}

.week-navigation__day-of-month {
  font-weight: 600;
  font-size: 0.825em;
}

.week-navigation__day.is-complete  .week-navigation__workout-indicator,
.week-navigation__day.is-active.is-future.is-complete .week-navigation__workout-indicator,
.week-navigation__day.is-active.is-today.is-complete .week-navigation__workout-indicator {
  border-color: rgba(0,0,0,0);
  border-width: 0;
}

.week-navigation__day.is-active {
  border: 2px solid #FCB424;
  padding: 4px;
  border-radius: 8px;
}

h2.workout-list__title {
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 0.5em;
}

.workout-list__info {
  padding: 16px;
  font-size: 14px;
  background: #e0e0e0;
  border-radius: 8px;
  margin: 12px 0 16px;
}

.workout-list {
  padding: 1em 2em;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
}

.workout-list__wrapper--knowledge {
  background: black;
  margin-left: -32px;
  margin-right: -32px;
  padding: 32px;
}

.workout-list__wrapper--knowledge h2 {
  margin-bottom: 1em;
  color: white;
}

.workout-list__teaser-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1em;
}

.workout-list__teaser-wrapper > *:only-child {
  width: 100%;
}

.workout-list__teaser-wrapper > * {
  width: calc(50% - 0.5em);
}

.workout-list__teaser {
  position: relative;
  border-radius: 8px;
  background: black no-repeat center/cover;
}

.workout-list__teaser--double {
  width: 100%;
}

.workout-list__teaser::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  z-index: 0;
}

.workout-list__teaser--wotext {
  background-size: contain;
}

.workout-list__teaser--wotext::before {
  background-color: transparent;
}

.workout-list__teaser--big-5 {
  background-image: url("assets/images/big-b.jpg");
}

.workout-list__teaser--assistance {
  background-image: url("assets/images/assistance.png");
}

.workout-list__teaser--core {
  background-image: url("assets/images/core.png");
}

a.workout-list__workout {
  padding: 4.75em 0;
  display: block;
  color: #fff;
  text-align: center;
  position: relative;
  z-index: 1;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 0.875em;
}

.popup-modal {
  background: rgba(0,0,0,0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-modal__content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  z-index: 100;
  border-radius: 10px;
  padding: 24px;
  max-width: 500px;
  max-height: calc(100vh - 146px);
  overflow-y: auto;
}

@media (max-width: 499px) {
  .popup-modal__content {
    min-width: calc(100vw - 48px);
    max-width: calc(100vw - 48px);
  }
}

.popup-modal h2 {
  font-size: 18px;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 1em;
}

.popup-modal h2.left {
  text-align: left;
}

.popup-modal p {
  font-weight: 500;
  line-height: 1.45em;
  font-size: 14px;
  margin-bottom: 1em;
}

.popup-modal p.center {
  text-align: center;
}

.popup-modal__image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
}

.popup-modal__image-wrapper img {
  max-width: 100%;
  height: auto;
}

.popup-modal__actions {
  margin-top: 1.5em;
}

button.popup-modal__close {
  position: absolute;
  right: 0;
  top: 0;
  padding: 24px;
  z-index: 999;
}

button.popup-modal__close:hover {
  cursor: pointer;
}


.popup-modal__text-actions {
  margin-top: 0.75em;
}

.popup-modal__pagination {
  position: fixed;
  bottom: 24px !important;
}

.popup-modal__pagination .swiper-pagination-bullet {
  background: white;
}

.popup-modal__pagination .swiper-pagination-bullet-active {
  background: white !important;
}

.workout-info {
  opacity: 1 !important;
  position: fixed;
  left: 0;
  right: 0;
  top: 52px;
  height: calc(100dvh - 52px);
  bottom: 0;
  background: white;
  z-index: 1000;
  padding: 32px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-width: 1000px;
  margin: 0 auto;
}

.workout-info__fact-content {
  text-align: right;
}

.workout-info__fact-link {
  display: flex;
  gap: 0.5em;
  align-items: center;
  margin-top: 0.625em;
  font-size: 12px;
  justify-content: flex-end;
}

.workout-info__fact-link img {
  position: relative;
  top: 1px;
}

.workout-info__fact-link span {
  text-decoration: underline;
}

.workout-info__navigation {
  display: flex;
  gap: 24px;
}

.workout-info__workout-title {
  font-weight: 800;
  text-transform: uppercase;
  font-size: 20px;
  margin-bottom: 24px;
}

.workout-info__workout-title.inactive {
  opacity: 0.3;
}

.workout-info__subtitle {
  font-weight: 800;
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 1em;
}

.workout-info__facts {
  margin-bottom: 40px;
}

.workout-info__fact {
  display: flex;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  padding: 12px 0;
  font-size: 13px;
  font-weight: 500;
  justify-content: space-between;
  gap: 24px;
}

.workout-info__fact-title {
  font-weight: 700;
}

.workout-info__fact:first-child {
  border-top: 1px solid rgba(0,0,0,0.1);
}

.workout-info__exercises-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.workout-info__exercises {
  margin-bottom: 24px;
}

.workout-info__exercise {
  display: flex;
  gap: 16px;
  align-items: center;
  position: relative;
  padding-right: 48px;
  opacity: 0.5;
}

.workout-info__exercise.is-selected {
  opacity: 1;
}

.workout-info__exercise-image {
  min-width: 64px;
  border-radius: 8px;
}

.workout-info__exercise-image img {
  border-radius: 8px;
}

.workout-info__exercise-title {
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 2px;
}

.workout-info__exercise-meta {
  font-size: 12px;
  opacity: 0.65;
  font-weight: 500;
}

.workout-info__exercise-selector {
  width: 24px;
  min-height: 24px;
  position: absolute;
  right: 0;
}

.workout-info__description {
  font-size: 14px;
  line-height: 1.5em;
  font-weight: 500;
  margin-bottom: 40px;
}

.workout-info__workout-start {
  margin-top: auto;
}

.password-reset {
  align-self: flex-start;
  font-size: 0.9em;
}

.error-message {
  font-size: 0.9em;
  color: darkred;
}